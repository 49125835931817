import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';

export const sortDropwdownOptionsByName = (
  options: IDropdownOptions[],
): IDropdownOptions[] =>
  options.sort((a, b) => {
    if (a.isPlaceholder && !b.isPlaceholder) {
      return -1;
    }
    if (!a.isPlaceholder && b.isPlaceholder) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
