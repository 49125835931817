import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { postDataToEndpoint, wait } from '@utils';
import { Endpoints } from '@utils/enums';

interface IUseCloseCurrentAssignmentQuery {
  closeModal?: () => void;
  contractDetailsQueryKey: QueryKey;
  contractUuid: string;
}

export const useCloseCurrentAssignmentQuery = ({
  closeModal,
  contractDetailsQueryKey,
  contractUuid,
}: IUseCloseCurrentAssignmentQuery) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const {
    isLoading: isClosingCurrentAssignment,
    mutate: closeCurrentAssignment,
  } = useMutation<unknown, AxiosError<IError>>(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.CloseCurrentAssignment}/${contractUuid}`,
      }),
    {
      onSuccess: async () => {
        // Timer is needed here as it takes a second for collections to update
        await wait(1000);
        queryClient.invalidateQueries(contractDetailsQueryKey);
        message.success(
          'OPS_PORTAL.STATUS.SUCCESSFULLY_CLOSED_CURRENT_ASSIGNMENT',
        );
        closeModal?.();
      },
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.UNABLE_TO_CLOSE_CURRENT_ASSIGNMENT',
        );
      },
    },
  );

  return {
    isClosingCurrentAssignment,
    closeCurrentAssignment,
  };
};
