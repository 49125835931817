import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldRHF } from '@components';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';
import { IMerchantStoreFormProps } from './types';

export const MerchantStoreForm = ({
  formID,
  handleSubmit,
  merchantStoreData,
}: IMerchantStoreFormProps) => {
  const { translate } = useI18n();

  const defaultValues = merchantStoreData ?? {
    store_name: '',
    store_number: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        store_number: Yup.string()
          .required(translate(ValidationErrorKeys.Required))
          .max(100),
        store_name: Yup.string()
          .required(translate(ValidationErrorKeys.Required))
          .max(200),
      }),
    ),
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <form
        className="form"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <TextFieldRHF
          id="store_name"
          label={`${translate('TITLE.STORE_NAME')}`}
          name="store_name"
        />
        <TextFieldRHF
          id="store_number"
          label={`${translate('TITLE.STORE_NUMBER')}`}
          name="store_number"
        />
      </form>
    </FormProvider>
  );
};
