import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { Label, Select } from '@components/Common.styles';
import { useI18n, useOriginator } from '@hooks';
import { OriginatorDisplayNamesHeyLight, Originators } from '@utils/constants';

type Originator = ValueOf<typeof Originators>;

interface IProfilePickerProps {
  userProfiles: Originator[];
}

const PickerWrapper = styled.div.attrs({ className: 'profile_picker' })`
  #kt_aside & {
    margin-block-start: 20px;
    margin-block-end: -10px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;

    .aside-minimize:not(.aside-minimize-hover) & {
      display: none;
    }
  }
`;

export const ProfilePicker = ({ userProfiles }: IProfilePickerProps) => {
  const { translate } = useI18n();
  const { originator, setOriginator } = useOriginator();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [originator, queryClient]);

  const handleSelect = useCallback(
    // The second argument tells the function this is coming from the picker.
    e => setOriginator(e.target.value, true),
    [setOriginator],
  );

  return (
    <PickerWrapper>
      <Label htmlFor="profilePicker">
        {translate('LABEL.CHOOSE_A_PROFILE')}
      </Label>
      <Select id="profilePicker" onChange={handleSelect} value={originator}>
        {userProfiles.map(profile => (
          <option key={profile} value={profile}>
            {OriginatorDisplayNamesHeyLight[profile]}
          </option>
        ))}
      </Select>
    </PickerWrapper>
  );
};
