import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { IMerchantStoreFormSubmit } from '../types';

export const useCreateMerchantStoreQuery = (closeModal?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const { isLoading: createMerchantStoreLoading, mutate: createMerchantStore } =
    useMutation<void, AxiosError<IError>, IMerchantStoreFormSubmit>(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.MerchantStore,
          requestData,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.MERCHANT_STORE]);
          queryClient.invalidateQueries([QueryKeys.MERCHANT_STORES_TABLE_DATA]);
          message.success('STATUS.SUCCESSFULLY_CREATED_MERCHANT_STORE');
          closeModal?.();
        },
        onError: error =>
          handleAxiosError(error, 'STATUS.FAILED_TO_CREATE_MERCHANT_STORE'),
      },
    );

  return {
    createMerchantStoreLoading,
    createMerchantStore,
  };
};
