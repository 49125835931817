import { PrimitiveType } from '@heidi-pay/heidi-common-fe/types';
import { supportedLanguages } from '@utils/constants';
import translations from './translations/en.json';

export type TranslateKey = keyof typeof translations;
export type TranslateInterpolation = Record<
  string,
  PrimitiveType | JSX.Element
>;

// allow both string and translate key overloads to give better intellisense
export type Translate = {
  (id: TranslateKey, interpolations?: TranslateInterpolation): string;
  (id: string, interpolations?: TranslateInterpolation): string;
};

export interface IGetLocaleDateOptions {
  date: string;
  formatOverride?: Intl.DateTimeFormatOptions;
  includeTime?: boolean;
}

export type GetLocaleDate = (options: IGetLocaleDateOptions) => string;

export interface IGetLocaleCurrencyValueOptions {
  currency?: string;
  value?: string;
}

export type GetLocaleCurrencyValue = (
  options: IGetLocaleCurrencyValueOptions,
) => string | undefined;

export type SupportedLanguage = (typeof supportedLanguages)[number];
