import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Card, DataDisplay, Loading } from '@components';
import { useConfig, useHasPermission, useI18n } from '@hooks';
import { selectTimeToAuthExpiry } from '@selectors/selectTimeToAuthExpiry';
import { fetchDataFromEndpoint, getErrorMessage } from '@utils';
import { QueryKeys } from '@utils/constants';
import { getDisplayDataList } from './getDisplayDataList';
import { OrganisationForm } from './OrganisationForm';

export const Organisation = () => {
  const { translate } = useI18n();
  const config = useConfig();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const {
    data: industryChoicesData,
    error: industryChoicesError,
    isLoading: isLoadingIndustryChoices,
  } = useQuery(
    [QueryKeys.INDUSTRY_CHOICES],
    () => fetchDataFromEndpoint('org/industries'),
    {
      cacheTime: msUntilTokenExpiry,
      staleTime: msUntilTokenExpiry,
    },
  );

  const {
    data: organisationData,
    error: organisationError,
    isLoading: isLoadingOrganisation,
  } = useQuery(
    [QueryKeys.ORGANISATION_INFO],
    () => fetchDataFromEndpoint('org/settings'),
    {
      cacheTime: msUntilTokenExpiry,
      staleTime: msUntilTokenExpiry,
    },
  );

  const canEditMerchantSettings = useHasPermission([
    'merchant_portal.can_edit_merchant_settings',
  ]);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancelClick = useCallback(
    () => setIsEditing(false),
    [setIsEditing],
  );
  const handleEditClick = useCallback(() => setIsEditing(true), [setIsEditing]);

  if (isLoadingIndustryChoices || isLoadingOrganisation) {
    return <Loading />;
  }

  if (industryChoicesError || organisationError) {
    return (
      <p>
        {getErrorMessage({
          error: industryChoicesError || organisationError,
          translate,
        })}
      </p>
    );
  }

  const cancelButton = [
    {
      key: 'cancelEditOrgInfo',
      clickHandler: handleCancelClick,
      dependencies: [canEditMerchantSettings, config.canEditOrgInfo],
      text: translate('BUTTON.CANCEL'),
      variant: 'secondary',
    },
  ];
  const editButton = [
    {
      key: 'editOrgInfo',
      clickHandler: handleEditClick,
      dependencies: [canEditMerchantSettings, config.canEditOrgInfo],
      text: translate('BUTTON.EDIT'),
    },
  ];

  const ctaButton = isEditing ? cancelButton : editButton;

  const industryChoices = industryChoicesData.map(choice => ({
    id: choice.id,
    name: choice.industry_name,
  }));

  const dataList = getDisplayDataList({
    config,
    industryChoices,
    organisationData,
    translate,
  });

  return (
    <Card buttons={ctaButton} title={translate('TITLE.ORG_INFO')}>
      {isEditing ? (
        <OrganisationForm
          data={organisationData}
          industryChoices={industryChoices}
          setIsEditing={setIsEditing}
        />
      ) : (
        <DataDisplay dataList={dataList} />
      )}
    </Card>
  );
};
