import { useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card, QueryStateRouter, ServersideTable } from '@components';
import { ITableRefObject } from '@components/Table/types';
import { useI18n, useQueryState } from '@hooks';
import { IOpsPortalPausedContracts } from '@schemas/opsPortal/types/pausedContracts';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, Views } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getPausedContractsTableColumns } from './getPausedContractColumns';
import { mapPausedContractsTableData } from './mapPausedContractsTableData';
import { IPausedContractTableColumns } from './types';

export const PausedContracts = () => {
  const { getLocaleDate, translate } = useI18n();

  const view = Views.PAUSED_CONTRACTS;

  const refToDataTable =
    useRef<ITableRefObject<IPausedContractTableColumns>>(null);

  const {
    activateQueryStateHook,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(view);

  const {
    data: contractsData,
    error: contractsError,
    isLoading: isContractsLoading,
  } = useQuery<IOpsPortalPausedContracts, AxiosError>(
    [QueryKeys.PAUSED_CONTRACTS, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchPausedContracts, {
        query: { ...queryParams },
      }),
  );

  const columns = useMemo(
    () => getPausedContractsTableColumns(translate),
    [translate],
  );

  const contractsTableData = mapPausedContractsTableData({
    data: contractsData?.results,
    getLocaleDate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <QueryStateRouter error={contractsError} isLoading={isContractsLoading}>
      <Card title={translate('OPS_PORTAL.TITLE.PAUSED_CONTRACTS')}>
        <ServersideTable
          columns={columns}
          data={contractsTableData}
          manageControlledState={updateQueryParams}
          pagination={{
            limit: queryParams.limit,
            totalItems: contractsData?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </Card>
    </QueryStateRouter>
  );
};
