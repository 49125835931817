import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalCollectionsListAgencies } from '@schemas/opsPortal/types/collectionsListAgencies';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

export const useGetAgenciesListQuery = () => {
  const {
    data: agenciesListData,
    error: agenciesListError,
    isLoading: isLoadingAgenciesList,
  } = useQuery<IOpsPortalCollectionsListAgencies, AxiosError>(
    [QueryKeys.LIST_AGENCIES],
    () => fetchDataFromEndpoint(Endpoints.FetchAgenciesList),
  );

  return {
    agenciesListData,
    agenciesListError,
    isLoadingAgenciesList,
  };
};
