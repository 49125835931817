import { Translate } from '@hooks/useI18n';
import { IGroup, IUserGroupNames } from '@schemas/merchantPortal/types/groups';
import {
  IMerchantStore,
  IMerchantStores,
} from '@schemas/merchantPortal/types/merchantStores';
import { dictionary } from '@utils';

const groups = dictionary.groups as Record<IUserGroupNames, string>;

interface IDropdownOption {
  id: string;
  name: string;
}

export const getGroupsDropdownOptions = (
  groupsData: IGroup[],
  translate: Translate,
): IDropdownOption[] => [
  { id: '', name: '—' },
  ...(groupsData
    ? groupsData.filter(Boolean).map((group: IGroup) => ({
        id: group.id.toString(),
        name: translate(groups[group.name] || group.name),
      }))
    : []),
];

export const getMerchantStoresDropdownOptions = (
  merchantStores: IMerchantStores = [],
): IDropdownOption[] => [
  { id: '', name: '—' },
  ...(merchantStores
    ? merchantStores.map((store: IMerchantStore) => ({
        id: store.uuid,
        name: store.store_name,
      }))
    : []),
];
