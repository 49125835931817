import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { Logo } from '@components/Logo';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { toAbsoluteUrl } from '@utils';
import { LogoVariants } from '@utils/constants';

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(
    () => ({
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile'),
    }),
    [uiService],
  );

  const logoFill = LogoVariants.LIGHT;

  return (
    <div
      className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
      id="kt_header_mobile"
      {...layoutProps.headerMobileAttributes}
    >
      {/* begin::Logo */}
      <Link to="/">
        <Logo fill={logoFill} />
      </Link>
      {/* end::Logo */}

      {/* begin::Toolbar */}
      <div className="d-flex align-items-center">
        {layoutProps.asideDisplay && (
          <>
            {/* begin::Aside Mobile Toggle */}
            <button
              className="btn p-0 burger-icon burger-icon-left"
              id="kt_aside_mobile_toggle"
            >
              <span />
            </button>
            {/* end::Aside Mobile Toggle */}
          </>
        )}

        {layoutProps.headerMenuSelfDisplay && (
          <>
            {/* begin::Header Menu Mobile Toggle */}
            <button
              className="btn p-0 burger-icon ml-4"
              id="kt_header_mobile_toggle"
            >
              <span />
            </button>
            {/* end::Header Menu Mobile Toggle */}
          </>
        )}

        {/* begin::Topbar Mobile Toggle */}
        <button
          className="btn btn-hover-text-primary p-0 ml-2"
          id="kt_header_mobile_topbar_toggle"
        >
          <span className="svg-icon svg-icon-xl">
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
          </span>
        </button>
        {/* end::Topbar Mobile Toggle */}
      </div>
      {/* end::Toolbar */}
    </div>
  );
}
