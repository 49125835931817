import { useEffect, useMemo, useState } from 'react';
import { Card, QueryStateRouter, ServersideTable } from '@components';
import {
  ModalKeys,
  useGetMerchantStoresQuery,
  useI18n,
  useModal,
  useQueryState,
} from '@hooks';
import { getCursorPaginationCallbacks } from '@utils';
import { TableNames } from '@utils/constants';
import { ButtonVariant } from '@utils/enums';
import { getColumns } from './getColumns';
import { mapData } from './mapData';

export const MerchantStores = () => {
  const [storeUuid, setStoreUuid] = useState<string>();
  const { getLocaleDate, translate } = useI18n();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.MERCHANT_STORES);

  const { isMerchantStoresLoading, merchantStoresData, merchantStoresError } =
    useGetMerchantStoresQuery(queryParams, stringifiedQueryParams);

  const createMerchantStoreModal = useModal(ModalKeys.MerchantStoreCreate, {
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  });

  const updateMerchantStoreModal = useModal(ModalKeys.MerchantStoreUpdate, {
    storeUuid,
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  });

  const filters = getFilters();

  const columns = useMemo(
    () =>
      getColumns({
        translate,
        setStoreUuid,
        ctaProps: updateMerchantStoreModal?.ctaProps,
      }),
    [updateMerchantStoreModal?.ctaProps, translate],
  );
  const mappedData = mapData({
    data: merchantStoresData?.results,
    getLocaleDate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: merchantStoresData?.next_cursor,
    prevCursor: merchantStoresData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <Card
      buttons={
        createMerchantStoreModal
          ? [createMerchantStoreModal?.ctaProps]
          : undefined
      }
      title={translate('TITLE.MERCHANT_STORES')}
    >
      <QueryStateRouter
        error={merchantStoresError}
        isLoading={isMerchantStoresLoading}
      >
        <ServersideTable
          columns={columns}
          data={mappedData}
          filters={filters}
          isCursorPagination={true}
          manageControlledState={updateQueryParams}
          pagination={{
            gotoNextPage,
            gotoPrevPage,
            pageSize: queryParams.pageSize,
            totalItems: merchantStoresData?.count,
          }}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </QueryStateRouter>
      {updateMerchantStoreModal?.Modal}
      {createMerchantStoreModal?.Modal}
    </Card>
  );
};
