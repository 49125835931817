import { Permissions } from '@appTypes';

export const consumerScopes: Permissions = [
  'consumer_portal.add_cardchangesession',
  'consumer_portal.add_consumertitle',
  'consumer_portal.add_consumeruser',
  'consumer_portal.add_consumerusercommunicationevent',
  'consumer_portal.add_consumeruserpasswordresettoken',
  'consumer_portal.add_consumerverificationcode',
  'consumer_portal.can_change_card',
  'consumer_portal.can_view_communication_events',
  'consumer_portal.can_view_documents',
  'consumer_portal.can_view_new_pago_updates',
  'consumer_portal.can_view_payment_plans',
  'consumer_portal.change_cardchangesession',
  'consumer_portal.change_consumertitle',
  'consumer_portal.change_consumeruser',
  'consumer_portal.change_consumerusercommunicationevent',
  'consumer_portal.change_consumeruserpasswordresettoken',
  'consumer_portal.change_consumerverificationcode',
  'consumer_portal.delete_cardchangesession',
  'consumer_portal.delete_consumertitle',
  'consumer_portal.delete_consumeruser',
  'consumer_portal.delete_consumerusercommunicationevent',
  'consumer_portal.delete_consumeruserpasswordresettoken',
  'consumer_portal.delete_consumerverificationcode',
  'consumer_portal.execute_prepayment',
  'consumer_portal.view_cardchangesession',
  'consumer_portal.view_consumertitle',
  'consumer_portal.view_consumeruser',
  'consumer_portal.view_consumerusercommunicationevent',
  'consumer_portal.view_consumeruserpasswordresettoken',
  'consumer_portal.view_consumerverificationcode',
];

export const merchantScopes: Permissions = [
  'merchant_portal.add_merchantinvoice',
  'merchant_portal.add_merchantuser',
  'merchant_portal.add_merchantuserpasswordresettoken',
  'merchant_portal.can_adjust_contract_value',
  'merchant_portal.can_cancel_contract',
  'merchant_portal.can_cancel_payment_plans',
  'merchant_portal.can_chargeback_payment_plans',
  'merchant_portal.can_create_a_new_plan',
  'merchant_portal.can_dispatch_order',
  'merchant_portal.can_download_payment_plans',
  'merchant_portal.can_edit_account_info',
  'merchant_portal.can_edit_invoices',
  'merchant_portal.can_edit_merchant_settings',
  'merchant_portal.can_edit_order_settings',
  'merchant_portal.can_edit_user_permissions',
  'merchant_portal.can_edit_user_settings',
  'merchant_portal.can_merchant_deactivate_user',
  'merchant_portal.can_refund_payment_plans',
  'merchant_portal.can_upload_signed_agreement',
  'merchant_portal.can_view_account_info',
  'merchant_portal.can_view_applications',
  'merchant_portal.can_view_awaiting_dispatch_orders',
  'merchant_portal.can_view_invoices',
  'merchant_portal.can_view_mediobanca_reconciliation',
  'merchant_portal.can_view_merchant_guide',
  'merchant_portal.can_view_merchant_keys',
  'merchant_portal.can_view_payment_plans',
  'merchant_portal.can_view_reconciliation',
  'merchant_portal.can_view_reporting',
  'merchant_portal.can_view_sales_assistant_limited_access',
  'merchant_portal.change_merchantinvoice',
  'merchant_portal.change_merchantuser',
  'merchant_portal.change_merchantuserpasswordresettoken',
  'merchant_portal.delete_merchantinvoice',
  'merchant_portal.delete_merchantuser',
  'merchant_portal.delete_merchantuserpasswordresettoken',
  'merchant_portal.view_merchantinvoice',
  'merchant_portal.view_merchantuser',
  'merchant_portal.view_merchantuserpasswordresettoken',
];

export const opsScopes: Permissions = [
  'ops_portal.accept_early_settlement',
  'ops_portal.balance_ui_activated',
  'ops_portal.block_applicant',
  'ops_portal.can_access_utilities',
  'ops_portal.can_assign_agency_to_contract',
  'ops_portal.can_assign_contract_to_bucket',
  'ops_portal.can_block_applicant',
  'ops_portal.can_block_collections_external_auto_referral',
  'ops_portal.can_close_assignment_to_external_agency',
  'ops_portal.can_create_acknowledgement_of_debt',
  'ops_portal.can_create_external_agency',
  'ops_portal.can_create_external_agency_user',
  'ops_portal.can_dispatch_order',
  'ops_portal.can_edit_merchant_iban',
  'ops_portal.can_enable_ops_user',
  'ops_portal.can_list_assigned_agency_contract',
  'ops_portal.can_manage_consumer_user',
  'ops_portal.can_modify_blocked_applicants',
  'ops_portal.can_pause_collections_communications',
  'ops_portal.can_pause_merchant_settlement',
  'ops_portal.can_pause_payment_processing_till_date',
  'ops_portal.can_reconcile_bank_account',
  'ops_portal.can_remove_late_fees',
  'ops_portal.can_send_email_to_consumer',
  'ops_portal.can_terminate_contracts',
  'ops_portal.can_toggle_pause_payment_processing',
  'ops_portal.can_unblock_applicant',
  'ops_portal.can_update_contract_arrears_managed_by',
  'ops_portal.can_upload_bank_account_statements',
  'ops_portal.can_upload_bank_account_transactions',
  'ops_portal.can_upload_csv_manual_payment',
  'ops_portal.can_view_bank_account_reconciliations',
  'ops_portal.can_view_bank_account_statement_lines',
  'ops_portal.can_view_blocked_applicants',
  'ops_portal.can_view_communication_events',
  'ops_portal.can_view_customer_agreements',
  'ops_portal.can_view_external_agency',
  'ops_portal.can_view_external_agency_user',
  'ops_portal.can_view_merchants_keys',
  'ops_portal.can_view_par_groups',
  'ops_portal.can_view_termination_eligible_contracts',
  'ops_portal.can_view_timeline_events',
  'ops_portal.can_view_translated_application_tracking',
  'ops_portal.cancel_early_settlement',
  'ops_portal.communications_access',
  'ops_portal.contract_date_change',
  'ops_portal.contract_date_change_individual',
  'ops_portal.create_early_settlement',
  'ops_portal.create_manual_note',
  'ops_portal.create_merchant',
  'ops_portal.create_settlement',
  'ops_portal.execute_prepayment',
  'ops_portal.fetch_early_settlement',
  'ops_portal.generate_certificate_of_interests',
  'ops_portal.list_certificate_of_interests',
  'ops_portal.list_merchants',
  'ops_portal.record_payment',
  'ops_portal.send_certificate_of_interests',
  'ops_portal.trigger_active_payment',
  'ops_portal.view_application',
  'ops_portal.view_application_action_logs',
  'ops_portal.view_awaiting_dispatch_contracts',
  'ops_portal.view_checkout_api_requests',
  'ops_portal.view_collections_contracts',
  'ops_portal.view_consumer',
  'ops_portal.view_contract',
  'ops_portal.view_contract_in_arrears',
  'ops_portal.view_merchant',
  'ops_portal.view_merchant_iban',
  'ops_portal.view_moneta',
  'ops_portal.view_overpaid_contracts',
  'ops_portal.view_paused_contracts',
  'portal_auth.view_externalagency',
];

export const scopes = Object.freeze({
  all: [...consumerScopes, ...merchantScopes, ...opsScopes],
  consumer: consumerScopes,
  merchant: merchantScopes,
  ops: opsScopes,
});
