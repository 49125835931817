import { Card, QueryStateRouter } from '@components';
import { useHasPermission, useI18n } from '@hooks';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { QueryKeys } from '@utils/constants';
import { useTrackingData } from './hooks/useTrackingData';
import { StepList } from './StepList';
import { TrackingGridItem } from './Tracking.styles';

export interface ITrackingStepListProps {
  contractId?: string;
  handleError?: () => void;
}

export const OpsApplicationTrackingCard = ({
  contractId,
  handleError,
}: ITrackingStepListProps) => {
  const canViewTranslatedActionTrackingCard = useHasPermission([
    'ops_portal.can_view_translated_application_tracking',
  ]);
  const { translate } = useI18n();
  const { data, error, isLoading } = useTrackingData({
    contractId,
    options: {
      handleError,
      isOpsEndpoint: true,
    },
    queryKey: [QueryKeys.ORDER_TRACKING, contractId],
  });

  if (!canViewTranslatedActionTrackingCard || !contractId) {
    return null;
  }

  return (
    <Card title={translate('PAGE.TRACKING.TRACK_CHECKOUT')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        <TrackingGridItem item={true} md={6}>
          <StepList
            progressData={
              data?.progressData as IMerchantPortalTracking['progressData']
            }
          />
        </TrackingGridItem>
      </QueryStateRouter>
    </Card>
  );
};
