import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { useI18n } from '@hooks';
import { usePaymentModal } from '@hooks/useModal/hooks';
import {
  IAdjustmentRequest,
  IManualPaymentRequest,
  IPrepaymentRequest,
  IReimbursementRequest,
  PaymentSubmitRequest,
} from '@hooks/useModal/hooks/usePaymentModal/types';
import { IFormModalProps, IModalProps } from '@hooks/useModal/types';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails.d';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { PaymentTypes, QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IContractPaymentModalProps extends IFormModalProps {
  contractUuid: string;
  data: IContractDetails;
  monetaData?: IOpsPortalMonetaContract;
  paymentType: ValueOf<typeof PaymentTypes>;
  uuid: string;
}

export const useContractPaymentModal = ({
  data,
  formStatusMessage,
  monetaData,
  paymentType,
  resolveForm,
  uuid,
}: IContractPaymentModalProps): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading: isManualPaymentLoading, mutate: mutateManualPayment } =
    useMutation(
      (requestData: IManualPaymentRequest) => {
        const { date, ...rest } = requestData;
        return postDataToEndpoint({
          endpoint: Endpoints.MakeManualPayment,
          requestData: {
            ...rest,
            settlement_date: date,
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.MANUAL_PAYMENT_SUCCESSFUL'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_MANUAL_PAYMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isPrepaymentLoading, mutate: mutatePrepayment } =
    useMutation(
      (requestData: IPrepaymentRequest) =>
        postDataToEndpoint({ endpoint: Endpoints.PrePayContract, requestData }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('NOTIFICATION.PREPAYMENT_SUCCESSFULLY_ISSUED'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey: 'LABEL.UNABLE_TO_PROCESS_PREPAYMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isReimbursementLoading, mutate: mutateReimbursement } =
    useMutation<void, AxiosError, IReimbursementRequest>(
      requestData => {
        return postDataToEndpoint({
          endpoint: Endpoints.ReimburseSettlement,
          requestData: {
            ...requestData,
            amount: {
              amount: requestData.amount,
              currency: requestData.currency,
            },
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.CONTRACT_DETAILS_DATA,
            uuid,
          ]);
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.REIMBURSEMENT_SUCCESSFUL'));
        },
        onError: error => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_REIMBURSEMENT',
              translate,
            }),
          );
        },
      },
    );

  const { isLoading: isAdjustmentLoading, mutate: mutateAdjustment } =
    useMutation<void, AxiosError, IAdjustmentRequest>(
      requestData => {
        return postDataToEndpoint({
          endpoint: Endpoints.AdjustSettlement,
          requestData: {
            ...requestData,
            amount: {
              amount: requestData.amount,
              currency: requestData.currency,
            },
          },
        });
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            uuid,
          ]);
          resolveForm(translate('OPS_PORTAL.LABEL.ADJUSTMENT_SUCCESSFUL'));
        },
        onError: (error: AxiosError) => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.LABEL.UNABLE_TO_PROCESS_ADJUSTMENT',
              translate,
            }),
          );
        },
      },
    );

  const isPaymentLoading =
    isReimbursementLoading ||
    isPrepaymentLoading ||
    isManualPaymentLoading ||
    isAdjustmentLoading;

  const handleManualPaymentSubmit = useCallback(
    values => mutateManualPayment(values),
    [mutateManualPayment],
  );

  const handleSubmit = useCallback(
    (values: PaymentSubmitRequest) => {
      const submitActions = {
        [PaymentTypes.ADJUSTMENTS]: mutateAdjustment,
        [PaymentTypes.REIMBURSEMENT]: mutateReimbursement,
        [PaymentTypes.PREPAYMENT]: mutatePrepayment,
        [PaymentTypes.MANUAL_PAYMENT]: handleManualPaymentSubmit,
      };

      submitActions[paymentType as keyof typeof submitActions](values);
    },
    [
      handleManualPaymentSubmit,
      mutateAdjustment,
      mutatePrepayment,
      mutateReimbursement,
      paymentType,
    ],
  );

  const submitTextKeys = {
    [PaymentTypes.ADJUSTMENTS]: 'OPS_PORTAL.BUTTON.ADJUST_SETTLEMENT',
    [PaymentTypes.MANUAL_PAYMENT]: 'OPS_PORTAL.BUTTON.MAKE_MANUAL_PAYMENT',
    [PaymentTypes.PREPAYMENT]: 'BUTTON.PREPAY_NOW',
    [PaymentTypes.REIMBURSEMENT]: 'OPS_PORTAL.BUTTON.REIMBURSE_AMOUNT',
  };

  const submitText = translate(
    submitTextKeys[paymentType as keyof typeof submitTextKeys],
  );

  const titleKeys = {
    [PaymentTypes.ADJUSTMENTS]: 'OPS_PORTAL.TITLE.ADJUSTMENTS',
    [PaymentTypes.MANUAL_PAYMENT]: 'OPS_PORTAL.TITLE.MANUAL_PAYMENT',
    [PaymentTypes.PREPAYMENT]: 'TITLE.PREPAYMENT',
    [PaymentTypes.REIMBURSEMENT]: 'OPS_PORTAL.TITLE.REIMBURSEMENT',
  };

  const title = translate(titleKeys[paymentType as keyof typeof titleKeys]);

  return usePaymentModal({
    data,
    formStatusMessage,
    handleSubmit,
    isPaymentLoading,
    monetaData,
    paymentType,
    resolveForm,
    submitText,
    title,
  });
};
