import { Translate } from '@hooks/useI18n';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { getFullName, getFormattedDate, getFormattedAmount } from '@utils';
import {
  CustomTransformKey,
  ICustomTransform,
  IFormatters,
  ISummaryField,
  KnownTransforms,
  OrderTrackingKey,
} from '../types';
import {
  getMediamarktArticleNumber,
  getMediamarktShoppingCardNumber,
} from './mediamarktTransforms';
import {
  getMobilezoneImei,
  getMobilezonePrice,
  getMobilezoneProductEan,
  getMobilezoneProductName,
} from './mobilezoneTransforms';

const formatAddress = ({
  data: { city, country, street, streetNumber, zipCode },
}: ICustomTransform) => {
  const address = [streetNumber, street, city, zipCode, country];
  return address.filter(Boolean).join(', ') || null;
};

const formatAmount = ({ data, formatters }: ICustomTransform) =>
  getFormattedAmount({
    amount: data.amount?.amount,
    currency: data.amount?.currency,
    getLocaleCurrencyValue: formatters.getLocaleCurrencyValue,
  });

const formatProducts = ({ data }: ICustomTransform) =>
  data.products.length
    ? JSON.stringify(
        data.products.map(x => ({
          name: x.name,
          price: x.price,
          quantity: x.quantity,
        })),
      )
    : null;

const formatStatusLabel = ({ data, translate }: ICustomTransform) =>
  translate(`STATUS.TRACKING_${data.status.toUpperCase()}`);

const transformFunctions: Record<
  CustomTransformKey,
  ({
    data,
    formatters,
    translate,
  }: ICustomTransform) => string | null | undefined
> = {
  amount: formatAmount,
  address: formatAddress,
  blank: () => '',
  dob: ({ data }) => getFormattedDate(data.dateOfBirth),
  fullName: ({ data }) => getFullName(data),
  mediamarktArticleNumber: ({ data }) => getMediamarktArticleNumber(data),
  mediamarktShoppingCardNumber: ({ data }) =>
    getMediamarktShoppingCardNumber(data),
  merchantStoreId: ({ data }) => data.merchantUserDetails?.store_id,
  merchantStoreName: ({ data }) => data.merchantUserDetails?.store_name,
  merchantStoreNumber: ({ data }) => data.merchantUserDetails?.store_number,
  merchantUserFullName: ({ data }) =>
    getFullName({
      firstName: data.merchantUserDetails?.merchant_user_first_name,
      lastName: data.merchantUserDetails?.merchant_user_last_name,
    }),
  mobilezoneProductEan: ({ data }) => getMobilezoneProductEan(data.products),
  mobilezoneProductImei: ({ data }) => getMobilezoneImei(data.products),
  mobilezoneProductName: ({ data }) => getMobilezoneProductName(data.products),
  mobilezoneProductPrice: ({ data }) => getMobilezonePrice(data.products),
  statusLabel: formatStatusLabel,
  products: formatProducts,
};

export const fieldTransform = (
  key: KnownTransforms,
  data: IMerchantPortalTracking,
  formatters: IFormatters,
  translate: Translate,
) => {
  const defaultTransform = (keyName: keyof IMerchantPortalTracking) =>
    data[keyName] ?? '';

  const transformFunction = transformFunctions[key as CustomTransformKey];

  return transformFunction
    ? transformFunction({ data, formatters, translate })
    : defaultTransform(key as OrderTrackingKey);
};

export const defaultSummaryFields: ISummaryField[] = [
  { key: 'PAGE.ORDERS.ORDER_ID', value: 'paymentReference' },
  { key: 'TITLE.CUSTOMER_NUMBER', value: 'orderReference' },
  { key: 'LABEL.AMOUNT', value: 'amount' },
  {
    key: 'TITLE.MERCHANT_USER',
    value: 'merchantUserEmail',
    options: { hideIfEmpty: true },
  },
  {
    key: 'TITLE.MERCHANT_USER_NAME',
    value: 'merchantUserFullName',
    options: { hideIfEmpty: true },
  },
  {
    key: 'TITLE.STORE_ID',
    value: 'merchantStoreId',
    options: { hideIfEmpty: true },
  },
  {
    key: 'TITLE.STORE_NUMBER',
    value: 'merchantStoreNumber',
    options: { hideIfEmpty: true },
  },
  {
    key: 'TITLE.STORE_NAME',
    value: 'merchantStoreName',
    options: { hideIfEmpty: true },
  },
  { key: 'TITLE.CUSTOMER_NAME', value: 'fullName' },
  { key: 'TITLE.DATE_OF_BIRTH', value: 'dob' },
  { key: 'TITLE.EMAIL', value: 'email' },
  { key: 'TITLE.PHONE_NUMBER', value: 'phoneNumber' },
  { key: 'LABEL.ADDRESS', value: 'address' },
  { key: 'TITLE.STATUS', value: 'statusLabel' },
];
