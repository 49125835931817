export enum ChangeCardDetailsSubkeys {
  CardChange = 'CardChange',
  ChangeDebitCard = 'ChangeDebitCard',
  ManagePaymentMethod = 'ManagePaymentMethod',
  SwitchPaymentMethod = 'SwitchPaymentMethod',
}

export enum ModalKeys {
  AddCertificateOfInterest = 'addCertificateOfInterestModal',
  AddNote = 'addNoteModal',
  AddPDR = 'addPDRModal',
  AdjustContractValue = 'adjustContractValueModal',
  AllocationDetails = 'allocationDetailsModal',
  AssignOverrideBucket = 'assignOverrideBucketModal',
  CancelContract = 'cancelContractModal',
  ChangeCardDetails = 'changeCardDetailsModal',
  ChangeIban = 'changeIbanModal',
  ChangePaymentDate = 'changePaymentDateModal',
  CloseCurrentAssignment = 'closeCurrentAssignment',
  ConfirmDispatch = 'confirmDispatchModal',
  ContractAdjustments = 'contractAdjustmentsModal',
  ContractManualPayments = 'contractManualPaymentModal',
  ContractPrepayment = 'contractPrepaymentModal',
  ContractReimbursement = 'contractReimbursementModal',
  CreateApplicantBlock = 'createApplicantBlockModal',
  CreateUser = 'createUserModal',
  DeleteFees = 'deleteFeesModal',
  DeleteUser = 'deleteUserModal',
  DownloadCustomerAgreement = 'downloadCustomerAgreement',
  DownloadQrPdfModal = 'downloadQrPdfModal',
  DownloadSettlementsReport = 'downloadSettlementsReport',
  EarlySettlementsCreateQuote = 'earlySettlementQuoteCreateModal',
  EarlySettlementsDisplay = 'earlySettlementsDisplayModal',
  EditAddress = 'editAddressModal',
  EditName = 'editNameModal',
  EditPhone = 'editPhoneModal',
  EditUser = 'editUserModal',
  EmailUser = 'emailUserModal',
  FileUpload = 'fileUploadModal',
  MerchantStoreCreate = 'merchantStoreCreateModal',
  MerchantStoreUpdate = 'merchantStoreUpdateModel',
  ModifyApplicantBlock = 'modifyApplicantBlockModal',
  OrderPrepayment = 'orderPrepaymentModal',
  OrderRefund = 'orderRefundModal',
  Password = 'passwordModal',
  PausePayments = 'pausePaymentsModal',
  PaymentLink = 'paymentLink',
  RemoveApplicantBlock = 'removeApplicantBlockModal',
  TriggerPayment = 'triggerPaymentModal',
  UpdateArrearsManagedBy = 'updateArrearsManagedByModal',
  ViewCommunication = 'viewCommunicationModal',
  ViewRawData = 'viewRawDataModal',
}

export enum PausePaymentsSubkeys {
  NotPaused = 'NotPaused',
  Paused = 'Paused',
}
