import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import { actions, agreeToPrivacyPolicy } from '@auth/_redux';
import { Loading, Button } from '@components';
import { useConfig, useI18n } from '@hooks';

const Container = styled.div`
  height: calc(100vh - 40px);
  overflow: auto;
`;

const ActionsFooter = styled(Grid)`
  @media (max-width: 599px) {
    text-align: center;
  }
  @media (min-width: 600px) {
    button {
      float: right;
    }
  }
  padding: 8px;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: #ffffff;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
`;

const StyledP = styled.p`
  margin: 0;
  font-weight: 500;
`;

export const PrivacyPolicySplashScreen = () => {
  const { privacyPolicy: PrivacyPolicy } = useConfig();
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, mutate: setAgreeToPrivacyPolicy } = useMutation(
    () => agreeToPrivacyPolicy(),
    {
      onSuccess: response => {
        const { agreed_to_privacy_policy_at: agreedToPrivacyPolicy } =
          response.data;
        dispatch(actions.setAgreedToPrivacyPolicy(agreedToPrivacyPolicy));
        navigate('/orders', { replace: true });
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      {PrivacyPolicy ? <PrivacyPolicy /> : null}
      <ActionsFooter
        alignItems="center"
        className="px-md-8 px-xs-4"
        container={true}
        justifyContent="center"
      >
        <Grid className="offset-md-1" item={true} md={8} sm={10} xs={12}>
          <StyledP>{translate('LABEL.PRIVACY_POLICY')}</StyledP>
        </Grid>
        <Grid className="pt-4 pt-sm-0" item={true} sm={2} xs={12}>
          <Button onClick={setAgreeToPrivacyPolicy} variant="primary">
            {translate('BUTTON.AGREE')}
          </Button>
        </Grid>
      </ActionsFooter>
    </Container>
  );
};
