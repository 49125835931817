import { AxiosError } from 'axios';
import { ErrorType } from '@heidi-pay/heidi-common-fe/enums';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalPaymentLink } from '@schemas/opsPortal/types/paymentLink';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseGetPaymentLinkQuery {
  contractUuid?: string;
}

interface ISoftError {
  cid: string;
  error_code: string;
  error_context: object;
  error_type: ErrorType;
  status_code: 500;
  title: string;
}

export const useGetPaymentLinkQuery = ({
  contractUuid,
}: IUseGetPaymentLinkQuery) => {
  const {
    data,
    error,
    isLoading: paymentLinkDataLoading,
  } = useQuery<IOpsPortalPaymentLink, AxiosError<ISoftError>>(
    [QueryKeys.PAYMENT_LINK, contractUuid],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchPaymentLink}/${contractUuid}`),
    {
      enabled: Boolean(contractUuid),
      retry: false,
    },
  );

  return {
    canUsePaymentLink: error?.response?.data.error_type !== ErrorType.SoftError,
    paymentLinkData: data,
    paymentLinkDataLoading,
  };
};
