import { useCallback } from 'react';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import { useCloseCurrentAssignmentQuery } from '@pages/ContractDetails/hooks/queries';
import { QueryKeys } from '@utils/constants';
import { CloseCurrentAssignmentForm } from './CloseCurrentAssignmentForm';
import { ICloseCurrentAssignment } from './types';

export const useCloseCurrentAssignmentModal = ({
  closeModal,
  contractUuid,
  externalContractUuid,
  formStatusMessage,
}: ICloseCurrentAssignment): IModalProps => {
  const { translate } = useI18n();

  const contractDetailsQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    externalContractUuid,
  ];

  const { closeCurrentAssignment, isClosingCurrentAssignment } =
    useCloseCurrentAssignmentQuery({
      closeModal,
      contractDetailsQueryKey,
      contractUuid,
    });

  const handleSubmit = useCallback(() => {
    closeCurrentAssignment();
  }, [closeCurrentAssignment]);

  const formID = 'closeCurrentAssignment';
  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isClosingCurrentAssignment}
    >
      <CloseCurrentAssignmentForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  return {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading: isClosingCurrentAssignment,
    modalContent,
    submitText: translate('OPS_PORTAL.TITLE.CLOSE_CURRENT_ASSIGNMENT'),
    title: translate('OPS_PORTAL.TITLE.CLOSE_CURRENT_ASSIGNMENT'),
  };
};
