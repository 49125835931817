import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { addPlaceholderForEmptyValues, getTranslatedData } from '@utils';
import { IPaymentScheduleTableData } from './types';

interface IMapPaymentDataProps {
  data?: IOpsPortalMonetaContract;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isScheduleHistory?: boolean;
  translate: Translate;
}

export const mapPaymentScheduleData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isScheduleHistory = false,
  translate,
}: IMapPaymentDataProps): IPaymentScheduleTableData[] =>
  data?.balances
    ?.filter(
      ({
        amount_due: amountDue,
        amount_paid: amountPaid,
        customer_refund: customerRefund,
        latest_schedule: latestSchedule,
      }) =>
        (Number(amountDue) !== 0 ||
          Number(amountPaid) !== 0 ||
          Number(customerRefund) !== 0) &&
        ((latestSchedule && !isScheduleHistory) || isScheduleHistory),
    )
    .map(dataRow => {
      const {
        amount_due: amountDue,
        amount_paid: amountPaid,
        arrears,
        customer_refund: customerRefund,
        payment_date: paymentDate,
        payment_type: paymentType,
        schedule_version: scheduleVersion,
        settlement_uuid: settlementUuid,
      } = dataRow;

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const givenDate = new Date(paymentDate);
      givenDate.setHours(0, 0, 0, 0);

      const { currency } = data;
      const amountDueString =
        amountDue &&
        getLocaleCurrencyValue({
          currency,
          value: amountDue,
        });

      const amount = Number(customerRefund) ? `-${customerRefund}` : amountPaid;
      const amountPaidString =
        amount &&
        getLocaleCurrencyValue({
          currency,
          value: amount,
        });

      const arrearsNumber = parseFloat(arrears);
      const arrearsString =
        arrears &&
        getLocaleCurrencyValue({
          currency,
          value: arrears,
        });

      const settlementData = settlementUuid
        ? data.settlements.filter(
            settlement => settlement.uuid === settlementUuid,
          )[0]
        : null;

      const isFuture = currentDate < givenDate;
      const focused = !isFuture || arrearsNumber <= 0;
      const paymentScheduleData =
        addPlaceholderForEmptyValues<IPaymentScheduleTableData>({
          amountDue: amountDueString,
          amountPaid: amountPaidString,
          arrears: focused ? arrearsString : null,
          paymentDate: getLocaleDate({ date: paymentDate }),
          paymentType,
          scheduleVersion: String(scheduleVersion),
          settlementReference: settlementData?.settlement_reference,
          settlementType: settlementData?.type
            ? getTranslatedData({
                term: settlementData?.type,
                translate,
              })
            : null,
        });
      return { ...paymentScheduleData, focused };
    }) ?? [];
