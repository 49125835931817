import * as privacyPolicies from '@assets/privacyPolicies';
import { getNavData } from '@hooks/useConfig/utils';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

// This is the config that the pagolight login page defaults to on /pagolight/auth/login endpoint
// Prior to login we are not aware of user permissions so using env to feature toggle instead
export const configMerchantPagolight: ConfigFunc = ({
  merchantSettingGroup,
}) => {
  const { hideOrdersPage, showApplicationsPage, showMerchantStoresPage } =
    merchantSettingGroup;

  // TODO - add back in when links available HC-13613
  const showHelpGuides = false;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditOrgInfo: false,
    canEditPhone: false,
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [
          NavItems.CREATE_NEW_PLAN,
          ...(showApplicationsPage ? [NavItems.APPLICATIONS] : []),
          ...(hideOrdersPage
            ? []
            : [
                NavItems.ORDERS,
                NavItems.AWAITING_CONFIRMATION,
                NavItems.SETTLEMENTS,
                NavItems.MEDIOBANCA_RECONCILIATIONS,
              ]),
        ],
      },
      {
        heading: NavSectionHeadingKeys.ORGANISATION,
        items: [
          NavItems.ORG_INFO,
          NavItems.USERS,
          ...(showMerchantStoresPage ? [NavItems.MERCHANT_STORES] : []),
        ],
      },
      ...(showHelpGuides
        ? [
            {
              heading: NavSectionHeadingKeys.HELP,
              items: [NavItems.MERCHANT_GUIDE, NavItems.INSTORE_SALES_GUIDE],
            },
          ]
        : []),
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [
          NavItems.ACCOUNT_SETTINGS,
          NavItems.MERCHANT_PRIVACY_POLICY,
          NavItems.LOGOUT,
        ],
      },
    ],
    privacyPolicy: privacyPolicies.Pagolight,
    showCompassOrders: true,
    showInvoiceDetails: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showLanguageDropdown: false,
    showOrgIndustry: false,
    showOrgPrimaryColour: false,
    showOrgSMS: false,
    showProfilePicker: true,
  });
};
