import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalCollectionsAcknowledgementOfDebt } from '@schemas/opsPortal/types/collectionsAcknowledgementOfDebt';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseGetAcknowledgementOfDebtQuery {
  contractUuid?: string;
}

export const useGetAcknowledgementOfDebtQuery = ({
  contractUuid,
}: IUseGetAcknowledgementOfDebtQuery) => {
  const {
    data: debtData,
    error: debtError,
    isLoading: isLoadingDebtData,
  } = useQuery<IOpsPortalCollectionsAcknowledgementOfDebt, AxiosError>(
    [QueryKeys.ACKNOWLEDGEMENT_OF_DEBT, contractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.GetAcknowledgementOfDebt}/${contractUuid}`,
        {},
      ),
    {
      enabled: Boolean(contractUuid),
      retry: false,
    },
  );

  return {
    debtData,
    debtError,
    isLoadingDebtData,
  };
};
