import { useCallback } from 'react';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import {
  useAddPDRQuery,
  useGetAgenciesListQuery,
} from '@pages/ContractDetails/hooks/queries';
import { QueryKeys } from '@utils/constants';
import { AddPDRForm } from './AddPDRForm';
import { IAddPDR } from './types';

export const useAddPDRModal = ({
  closeModal,
  contractUuid,
  externalContractUuid,
  formStatusMessage,
}: IAddPDR): IModalProps => {
  const { translate } = useI18n();

  const contractDetailsQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    externalContractUuid,
  ];

  const { agenciesListData, isLoadingAgenciesList } = useGetAgenciesListQuery();

  const { addPDR, isAddingPDR } = useAddPDRQuery({
    closeModal,
    contractDetailsQueryKey,
    contractUuid,
  });

  const handleSubmit = useCallback(
    async data => {
      addPDR(data);
    },
    [addPDR],
  );

  const formID = 'addPDR';
  const isLoading = isAddingPDR || isLoadingAgenciesList;
  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <AddPDRForm
        agenciesList={agenciesListData?.agencies ?? []}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  return {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.ADD_PDR'),
  };
};
