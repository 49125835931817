import {
  FooterLink,
  LoginFooter,
  LoginFooterNav,
  LoginSidePanel,
  SidePanelInnerWrapper,
} from '@auth/pages/Auth.styles';
import { useConfig, useI18n } from '@hooks';
import { Copyright } from './Copyright';
import { LoginGreeting } from './LoginGreeting';

export const SidePanel = () => {
  const { translate } = useI18n();
  const { showFooter } = useConfig();

  // add these back in when we have the urls available to us HC-13609
  const showLoginFooter = false;
  const showSwissFooter = false;

  return (
    <LoginSidePanel>
      <SidePanelInnerWrapper>
        <LoginGreeting />

        {showFooter && showLoginFooter ? (
          <LoginFooter>
            <Copyright
              companyLegalName={translate('GENERAL.COMPANY_LEGAL_NAME')}
            />

            {showSwissFooter ? (
              <LoginFooterNav>
                <FooterLink href="https://www.heidipay.com/en-ch/privacy-policy/">
                  {translate('LINK.PRIVACY')}
                </FooterLink>
                <FooterLink href="https://www.heidipay.com/en-ch/terms-conditions/">
                  {translate('LINK.TERMS')}
                </FooterLink>
                <FooterLink href="https://help.heidipay.com/">
                  {translate('LINK.HELP')}
                </FooterLink>
              </LoginFooterNav>
            ) : null}
          </LoginFooter>
        ) : null}
      </SidePanelInnerWrapper>
    </LoginSidePanel>
  );
};
