import { useCallback } from 'react';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import { MerchantStoreForm } from './MerchantStoreForm';
import {
  useCreateMerchantStoreQuery,
  useGetMerchantStoreQuery,
  useUpdateMerchantStoreQuery,
} from './queries';
import { IMerchantStoreFormSubmit, IMerchantStoreModalProps } from './types';

const useMerchantStoreModal = ({
  closeModal,
  formStatusMessage,
  storeUuid,
}: IMerchantStoreModalProps): IModalProps => {
  const { translate } = useI18n();

  const { isMerchantStoreDataLoading, merchantStoreData } =
    useGetMerchantStoreQuery(storeUuid);
  const { updateMerchantStore } = useUpdateMerchantStoreQuery(closeModal);
  const { createMerchantStore } = useCreateMerchantStoreQuery(closeModal);

  const handleSubmit = useCallback(
    (data: IMerchantStoreFormSubmit) => {
      if (storeUuid) {
        updateMerchantStore({
          ...data,
          uuid: storeUuid,
        });
      } else {
        createMerchantStore(data);
      }
    },
    [createMerchantStore, storeUuid, updateMerchantStore],
  );

  const formID = 'merchantStoreForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={Boolean(storeUuid && isMerchantStoreDataLoading)}
      loadingMessage={translate('STATUS.LOADING')}
    >
      <MerchantStoreForm
        formID={formID}
        handleSubmit={handleSubmit}
        merchantStoreData={merchantStoreData}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    closeModal,
    isDangerousSubmit: true,
    modalContent,
    title: translate('TITLE.MERCHANT_STORE'),
  };
};

export const useMerchantStoreCreateModal = ({
  closeModal,
  formStatusMessage,
  ...props
}: IMerchantStoreModalProps): IModalProps => {
  return useMerchantStoreModal({ ...props, closeModal, formStatusMessage });
};

export const useMerchantStoreUpdateModal = ({
  closeModal,
  formStatusMessage,
  storeUuid,
  ...props
}: IMerchantStoreModalProps): IModalProps => {
  return useMerchantStoreModal({
    ...props,
    closeModal,
    formStatusMessage,
    storeUuid,
  });
};
