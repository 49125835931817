import { ValueOf } from '@appTypes';
import { GetLocaleCurrencyValue, Translate } from '@hooks/useI18n';
import { IConsumerPortalArrearsData } from '@schemas/consumerPortal/types/arrearsData';
import { IOrderDetail as IConsumerOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { addPlaceholderForEmptyValues } from '@utils';
import { PaymentMethods } from '@utils/constants';
import { CompassSupportTextAndLink } from '../components/CompassSupportTextAndLink';
import { IScheduledPayment } from '../components/types';
import { IGetOrderSummaryProps, IOrderSummaryProps } from './types';

export interface IGetConsumerOrderSummaryProps extends IGetOrderSummaryProps {
  arrearsData?: IConsumerPortalArrearsData;
  isCompass: boolean;
  nextPayment?: IScheduledPayment;
  orderDetailsData: IConsumerOrderDetails;
  translate: Translate;
}

interface IGetPaidText {
  amount?: string;
  currency: string;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  isCompass: boolean;
  paymentMethod: ValueOf<typeof PaymentMethods>;
  translate: Translate;
}

const getPaidText = ({
  amount,
  currency,
  getLocaleCurrencyValue,
  isCompass,
  paymentMethod,
  translate,
}: IGetPaidText) => {
  if (isCompass && paymentMethod === PaymentMethods.DIRECT_DEBIT) {
    return (
      <CompassSupportTextAndLink
        supportTextKey="PAGE.ORDERS.UNABLE_TO_PROVIDE_PAID_INFORMATION"
        translate={translate}
      />
    );
  }

  return amount
    ? getLocaleCurrencyValue({ currency, value: amount })
    : translate('ERROR.TECHNICAL_ISSUE_DATA');
};

export const getConsumerOrderSummaryProps = ({
  arrearsData,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCompass,
  nextPayment,
  orderDetailsData,
  translate,
}: IGetConsumerOrderSummaryProps): IOrderSummaryProps => {
  const {
    amount: { amount, currency },
    amount_refunded: { amount: amountRefunded },
    created_date: date,
    late_fee_total: lateFeeTotal,
    merchant_contract_status: status,
    merchant_name: merchant,
    order_details: { order_reference: merchantOrderId },
    payment_method: paymentMethod,
    payment_plan_reference: paymentPlanId,
    received,
    system_remainder: { amount: systemRemainder },
  } = orderDetailsData;

  const orderValue = getLocaleCurrencyValue({
    currency,
    value: amount,
  });
  const lateFeeTotalString =
    lateFeeTotal?.amount &&
    getLocaleCurrencyValue({ currency, value: lateFeeTotal.amount });

  const balance = getLocaleCurrencyValue({ currency, value: systemRemainder });

  const nextPaymentAmount = nextPayment ? nextPayment.paymentAmount : null;
  const nextPaymentDate = nextPayment ? nextPayment.dueDate : null;
  const isInArrears = arrearsData?.is_in_arrears;
  const arrearsAmount = arrearsData?.arrears_amount;

  // NOTE: Do not alphabetise the object properties in any of the following code, as the order
  // of the Object.keys determines display order.

  const returnPropsFront = {
    date: date ? getLocaleDate({ date, includeTime: true }) : null,
    paymentPlanId,
    merchant,
    merchantOrderId,
    orderValue,
    lateFeeTotal: lateFeeTotalString,
    paid: getPaidText({
      paymentMethod,
      isCompass,
      amount: received?.amount,
      getLocaleCurrencyValue,
      translate,
      currency,
    }),
  };

  const returnPropsBack = {
    nextPaymentDate,
    amountRefunded: getLocaleCurrencyValue({
      currency,
      value: amountRefunded,
    }),
    status,
  };

  if (isCompass) {
    return addPlaceholderForEmptyValues({
      ...returnPropsFront,
      ...returnPropsBack,
    });
  }

  // Do not alphabetise these object properties, as the order
  // of the Object.keys determines display order.
  return addPlaceholderForEmptyValues({
    ...returnPropsFront,
    balance,
    ...(arrearsData
      ? {
          isInArrears: translate(isInArrears ? 'GENERAL.YES' : 'GENERAL.NO'),
          arrearsAmount:
            isInArrears &&
            getLocaleCurrencyValue({
              currency: arrearsAmount?.currency,
              value: arrearsAmount?.amount,
            }),
        }
      : {}),
    nextPaymentAmount,
    ...returnPropsBack,
  });
};
