import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { ICardCTA, ValueOf } from '@appTypes';
import { Card, QueryStateRouter, ServersideTable } from '@components';
import { CSVLink } from '@components/Common.styles';
import { ITableRefObject } from '@components/Table/types';
import {
  ModalKeys,
  useCollectionAgencies,
  useI18n,
  useModal,
  useOriginator,
  useQueryState,
  useUserDetails,
} from '@hooks';
import { IOpsPortalContracts } from '@schemas/opsPortal/types/contracts';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import {
  downloadCSV,
  fetchDataFromEndpoint,
  getContractsTableColumns,
  getMerchantsOptions,
  mapContractsTableData,
} from '@utils';
import { QueryKeys, Views } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { createContractsCSV } from './createContractsCSV';
import { IContractTableData } from './types';

interface IContracts {
  view?: ValueOf<typeof Views>;
}

export const Contracts = ({ view = Views.CONTRACTS }: IContracts) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isCompass, originator } = useOriginator();

  const { isInternalOpsUser } = useUserDetails();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(view);

  const queryKeys = {
    [Views.AWAITING_DISPATCH]: QueryKeys.AWAITING_DISPATCH_TABLE_DATA,
    [Views.COLLECTIONS]: QueryKeys.COLLECTIONS_TABLE_DATA,
    [Views.CONTRACTS]: QueryKeys.CONTRACTS_TABLE_DATA,
    [Views.OVERPAID_CONTRACTS]: QueryKeys.OVERPAID_CONTRACTS_TABLE_DATA,
    [Views.PAUSED_CONTRACTS]: QueryKeys.PAUSED_CONTRACTS_TABLE_DATA,
    [Views.TERMINABLE_CONTRACTS]: QueryKeys.TERMINABLE_CONTRACTS,
    [Views.CONTRACTS_BY_UUIDS]: QueryKeys.CONTRACTS_BY_UUIDS,
  };

  const [
    {
      data: contractsData,
      error: contractsError,
      isFetching: isContractsFetching,
      isLoading: isContractsLoading,
    },
    {
      data: merchantsData,
      error: merchantsError,
      isFetching: isMerchantsFetching,
      isLoading: isMerchantsLoading,
    },
  ] = useQueries<
    [[IOpsPortalContracts, AxiosError], [IOpsPortalMerchantsList, AxiosError]]
  >({
    queries: [
      {
        queryKey: [queryKeys[view], stringifiedQueryParams, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchContracts, {
            query: { ...queryParams, originator },
          }),
      },
      {
        queryKey: [QueryKeys.FULL_MERCHANT_LIST, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
            query: { originator },
          }),
      },
    ],
  });

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({ merchantsOptions });
  const filtersForAgencyUser = filters.filter(filter =>
    ['merchant', 'search'].includes(filter.id),
  );

  const [dispatchedUuid, setDispatchedUuid] = useState<string>();

  const confirmDispatchModal = useModal(ModalKeys.ConfirmDispatch, {
    uuid: dispatchedUuid,
    variant: [ButtonVariant.Danger, ButtonVariant.Sm],
  });

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  const columns = useMemo(
    () =>
      getContractsTableColumns({
        collectionAgencies,
        ctaProps: confirmDispatchModal?.ctaProps as ICardCTA,
        isCompass,
        isInternalOpsUser,
        setDispatchedUuid,
        translate,
        view,
      }),
    [
      collectionAgencies,
      confirmDispatchModal?.ctaProps,
      isCompass,
      isInternalOpsUser,
      translate,
      view,
    ],
  );

  const contractsTableData = mapContractsTableData({
    data: contractsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    translate,
  });

  const refToCSVLink = useRef(null);
  const refToDataTable = useRef<ITableRefObject<IContractTableData>>(null);

  const handleDownloadClick = useCallback(() => {
    const { columns: cols, rows } =
      refToDataTable.current as ITableRefObject<IContractTableData>;
    const csv = createContractsCSV({ columns: cols, data: rows, translate });
    downloadCSV({ csv, refToCSVLink });
  }, [translate]);

  const csvButtonText = translate(
    `OPS_PORTAL.BUTTON.DOWNLOAD_${view.toUpperCase()}`,
  );

  const buttons = [
    {
      key: 'downloadCSV',
      clickHandler: handleDownloadClick,
      text: csvButtonText,
    },
  ];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const cardTitle = {
    [Views.AWAITING_DISPATCH]: translate('STATUS.AWAITING_DISPATCH'),
    [Views.CONTRACTS]: translate('OPS_PORTAL.TITLE.ALL_CONTRACTS'),
    [Views.COLLECTIONS]: translate('OPS_PORTAL.TITLE.COLLECTIONS'),
  };

  return (
    <QueryStateRouter
      error={contractsError || merchantsError}
      isLoading={
        isContractsLoading ||
        isMerchantsLoading ||
        isContractsFetching ||
        isMerchantsFetching ||
        isCollectionAgenciesLoading
      }
    >
      <Card buttons={buttons} title={cardTitle[view as keyof typeof cardTitle]}>
        <CSVLink download={view} ref={refToCSVLink} />
        <ServersideTable
          columns={columns}
          data={contractsTableData}
          filters={isInternalOpsUser ? filters : filtersForAgencyUser}
          manageControlledState={updateQueryParams}
          pagination={{
            limit: queryParams.limit,
            totalItems: contractsData?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
        {confirmDispatchModal?.Modal}
      </Card>
    </QueryStateRouter>
  );
};
