import { Helmet } from 'react-helmet';
import { useOriginator } from '@hooks';
import { OriginatorDisplayNamesHeyLight } from '@utils/constants';

interface IGetFavicon {
  publicUrl?: string;
}

const getFavicon = ({ publicUrl }: IGetFavicon) => {
  return `${publicUrl}/media/logos/favicon.ico`;
};

export const BrowserTabThemeController = () => {
  const { originator } = useOriginator();
  const { PUBLIC_URL: publicUrl } = process.env;

  const favicon = getFavicon({ publicUrl });

  return (
    <Helmet>
      <title id="tab-id">
        {OriginatorDisplayNamesHeyLight[originator]} | Portal
      </title>
      <link href={favicon} id="tab-favicon" rel="icon" type="image/x-icon" />
      <style>{`
          html, body {
            font-family: 'HeyLight Sans', sans-serif;
          }
        `}</style>
    </Helmet>
  );
};
