import { IOpsPortalCommunicationsEvent } from '@schemas/opsPortal/types/communicationsEvent';
import { wrapDisplayData } from './wrapDisplayData';

export const getDisplayData = (data: IOpsPortalCommunicationsEvent) => {
  const { channel, content_sent: content, sent_to: to, subject } = data;

  if (channel === 'text_message') {
    // Just in case this is flagged by Sonar or PR reviews, we do want
    // ||, not ??, because if the content could be an empty string.
    return wrapDisplayData(`<em>To: ${to}</em><br />${content || subject}`);
  }

  if (subject) {
    return wrapDisplayData(
      `<em>To: ${to}<br />Subject: ${subject}</em><br />${content}`,
    );
  }

  return wrapDisplayData(`<em>To: ${to}</em><br />${content}`);
};
